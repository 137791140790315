@charset "UTF-8";

@import url(~bootstrap/dist/css/bootstrap.css);

@import url(~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css);

.badge.badge-danger {
  background-color: red;
  color: #fff;
}

.badge.badge-warning {
  background-color: darkorange;
  color: #fff;
}

.badge.badge-primary {
  background-color: #6095C1;
  color: #fff;
}

.text-warning {
  color: orange;
}

.tooltip-inner {
  white-space: pre-wrap;
}

.panel-default > .panel-heading,
.panel-default > .panel-footer {
  background-color: #fff;
  border-top: none;
}

.panel-default > .panel-heading .link-only,
.panel-default > .panel-footer .link-only {
  margin-top: 5px;
  position: absolute;
}

.panel-default > .panel-heading {
  border-bottom-color: #eee;
}

.panel-default > .panel-heading > .panel-title {
  font-size: 1.6em;
  color: #666666;
  padding-top: 6px;
}

.panel-login > .panel-body {
  min-height: 200px;
}

.panel-login-big {
  padding: 40px 15px;
}

.panel-direct {
  border: 0;
}

.panel-direct > .panel-body {
  border: 1px solid #ddd;
}

.panel-login ul {
  padding-left: 20px;
  list-style: none;
}

.panel-login ul li:before {
  content: "";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  display: inline-block;
  margin-left: -1.3em;
  margin-top: 3px;
  width: 1.3em;
}

/***
 * Global
 */

.container {
  padding-top: 0;
  padding-bottom: 0;
}

.container section {
  padding-bottom: 10px;
}

.full-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0;
}

.tooltip.in {
  opacity: 1;
}

.tooltip-inner {
  padding: 2px;
}

.tooltip-inner-content {
  padding: 5px 10px;
  font-size: 14px;
  background-color: white;
  color: black;
  border: 1px solid black;
}

div.silhouette {
  text-align: center;
}

span.silhouette,
svg.silhouette {
  font-size: 12em;
  color: #6095c1;
}

span.span171 {
  width: 171px;
}

.liste-selection-personne {
  cursor: pointer;
}

.liste-selection-personne:hover {
  background-color: #eee;
}

.text-center {
  margin-left: auto;
  margin-right: auto;
}

.form-icon-tooltip {
  margin-top: 7px;
  padding-left: 0;
}

form,
.form-group,
#form {
  display: inline;
}

label {
  margin-top: 5px;
}

label.control-label.required:after {
  content: ' *';
  color: red;
}

.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 5px;
}

.navbar-toggle {
  margin-right: 8px;
}

.navbar-cookie {
  background-color: red;
}

.navbar-cookie .navbar-text {
  color: #fff;
}

#cookie-bar {
  margin-bottom: 50px;
}

.logo-agfa {
  width: 70px;
  margin-bottom: 10px;
}

.locale_language {
  margin: 0;
}

.flag-icon {
  margin-right: 10px;
}

/***
 * Homepage
 */

div.layoutPublic .hero-icon {
  font-size: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: auto;
  height: 150px;
  line-height: 150px;
  width: 150px;
  text-align: center;
}

div.layoutPublic .panel-register {
  border-color: #6095C1;
  min-height: 250px;
  vertical-align: middle;
}

div.layoutPublic .panel-register .hero-icon {
  color: #eff5f9;
}

div.layoutPublic .panel-direct {
  border-color: #76923b;
  min-height: 250px;
}

div.layoutPublic .panel-direct .hero-icon {
  color: #f1f4eb;
}

div.layoutPublic .panel {
  position: relative;
  padding: 15px;
}

div.layoutPublic .panel-body {
  min-height: 250px;
  text-align: center;
  font-size: 150%;
  display: table;
}

div.layoutPublic .hero-content {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  z-index: 1;
  position: relative;
}

div.layoutPublic .container-fluid.login {
  font-size: 20px;
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

div.layoutPublic .login-link > a {
  text-transform: uppercase;
}

div.homepage .logo {
  padding-top: 15px;
  padding-bottom: 15px;
}

.logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Large Devices, Wide Screens */

/* Medium Devices, Desktops */

@media only screen and (min-width: 768px) {
  .crendu-element {
    padding: 6px 0;
  }

  .crendu-element svg {
    padding: 0 6px 0 0;
    width: auto !important;
  }

  .crendu-big-buttons .crendu-element {
    padding: 6px 0;
  }

  .crendu-elements .crendu-right.crendu-elements-right,
  .crendu-elements .crendu.crendu-read .crendu-right {
    padding: 10px 10px 0 0;
  }

  #crendu-elements-right a.crendu-element {
    border: none;
  }
}

@media only screen and (min-width: 992px) {
  div.homepage .logo {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .homepage-spacer {
    padding: 150px 0 100px 0;
  }
}

@media only screen and (max-width: 991px) {
  .space {
    display: block;
    height: 20px;
  }
}

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
  .logo {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  a.locale_language {
    padding: 5px;
  }

  span.silhouette {
    font-size: 8em;
  }
}

/* Custom, iPhone Retina */

@media only screen and (max-width: 320px) {
  span.silhouette {
    font-size: 6em;
  }
}

/**
 * Form
 */

.input-group-btn .btn {
  padding-bottom: 7px;
}

/**
 * Navbar
 */

.menu-bar .navbar {
  margin-bottom: 0;
  padding-bottom: 0;
}

.menu-bar .navbar-default {
  border: none;
}

.dropdown-link-text {
  display: inline-block;
  margin-right: 20px;
}

.dropdown-menu > li > a.personnes {
  width: 100%;
  margin-right: 80px;
}

.visible-xs .reportDates {
  background-color: #fafafa;
}

.visible-xs .reportDates .col-xs-6 {
  padding: 0 20px;
}

.paiement_confirm {
  margin-top: 17px;
}

#navbar .navbar-nav > li > a {
  padding: 15px 14px;
}

/**
 * GUI
 */

.clickme {
  cursor: pointer;
}

.col-left-border {
  padding-left: 15px;
  border-left: 1px solid #eee;
}

a.anchor,
a.anchor:hover,
a.anchor:focus {
  color: #e6e6e6;
  text-decoration: none;
}

/**
 * List of results
 */

.reports-list-right {
  padding: 0;
}

.reports-list-left,
.reports-list-right > a,
.reports-list-left-sm {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
}

.reports-list-left-high,
.reports-list-right-high > a {
  height: 155px;
}

.reports-list-left-higher,
.reports-list-right-higher > a {
  height: 210px;
}

.reports-list-details {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contentReportSecondary,
.contentReportSecondarySm {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  background-color: white;
  border: solid #ddd;
  border-width: 1px;
  border-radius: 5px;
  color: #666;
  box-shadow: 10px 10px 18px #555;
}

.contentReportSecondary:hover,
.contentReportSecondarySm:hover {
  background-color: #eee;
  cursor: pointer;
}

.reports-list-left,
.reports-list-left-sm {
  background-color: white;
  border: solid #ddd;
  border-width: 1px 0 1px 1px;
  border-radius: 5px 0 0 5px;
  color: #666;
}

.reports-list-left:hover,
.reports-list-left-sm:hover {
  background-color: #eee;
  cursor: pointer;
}

.reports-list-left .col-md-6,
.reports-list-left-sm .col-md-6 {
  padding-bottom: 5px;
}

.reports-list-left-sm {
  border-right: 1px solid #ddd;
  border-radius: 5px;
}

.reports-list-right,
.reports-list-right a {
  text-align: center;
  color: white;
}

.reports-list-right .col-md-4,
.reports-list-right a .col-md-4
.reports-list-right .col-md-4 a {
  background-color: #FF8C00;
  border-radius: 0 5px 5px 0;
}

.reports-list-right .col-md-4:hover,
.reports-list-right a .col-md-4:hover,
.reports-list-right .col-md-4 a:hover {
  background-color: #cc7000;
  color: white;
}

/* Menu highlights */

li.highlighted {
  background-color: #FF8C00;
}

li.highlighted a {
  color: white !important;
}

li.highlighted a:hover {
  background-color: #cc7000;
}

/* Badges for the list of patients */

.list-users-patients .badge {
  margin: 0 3px 3px 0;
  background: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.7);
  font-weight: normal;
}

.list-users-patients .badge.badge-transfer {
  background: rgba(70, 184, 218, 0.1);
  cursor: pointer;
}

.list-users-patients .badge.badge-transfer i,
.list-users-patients .badge.badge-transfer svg {
  color: rgba(70, 184, 218, 0.4);
}

.list-users-patients .badge.badge-shared {
  background: rgba(10, 160, 50, 0.1);
  cursor: pointer;
}

.list-users-patients .badge.badge-shared i,
.list-users-patients .badge.badge-shared svg {
  color: rgba(10, 160, 50, 0.4);
}

/* Styles for the search bar */

.row-search {
  margin: 0;
  padding: 0 0 5px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.row-search .col {
  display: table-cell;
  vertical-align: bottom;
  width: 20%;
  float: none;
  padding: 0 10px 0 0;
}

.row-search .col:last-child {
  width: 20%;
  text-align: right;
}

.row-search label {
  display: block;
  font-weight: normal;
  text-align: left;
  padding: 0;
  margin: 0 0 1px;
  font-size: 10px;
}

.row-search input.form-control {
  display: block;
  width: 100%;
}

/* Styles for the admin quick forms */

.row-quickform {
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.row-quickform .col,
.row-quickform .col-medium,
.row-quickform .col-large {
  display: table-cell;
  vertical-align: bottom;
  width: 20%;
  float: none;
  padding: 0 10px 0 0;
}

.row-quickform .col-medium {
  width: 40%;
}

.row-quickform .col-large {
  width: 60%;
}

.row-quickform .col:last-child {
  width: 20%;
  text-align: right;
}

.row-quickform label {
  display: block;
  font-weight: normal;
  text-align: left;
  padding: 0;
  margin: 0 0 1px;
  font-size: 10px;
}

.row-quickform input.form-control,
.row-quickform select.form-control {
  display: block;
  width: 100%;
}

/* Fix for the allowed IPs form */

.allowedIps-fields .row {
  padding: 0 0 10px;
}

/* Special case for the roles */

.roles-checkboxes {
  margin: 0 0 10px;
}

.roles-checkboxes:after {
  display: table;
  content: "";
  clear: both;
}

.roles-checkboxes .checkbox {
  float: left;
  padding: 5px 10px 5px 50px;
  margin: 0 -20px 0 0;
  height: 30px;
  background: #EFEFEF;
  color: #666666;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  -webkit-border-bottom-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-border-top-left-radius: 20px;
  border-top-left-radius: 20px;
  background-clip: padding-box;
}

.roles-checkboxes .checkbox:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: -15px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left-width: 15px;
  border-left-style: solid;
  border-left-color: #EFEFEF;
}

.roles-checkboxes .checkbox.selected {
  background: #5bc0de;
}

.roles-checkboxes .checkbox.selected label {
  color: rgba(255, 255, 255, 0.5);
}

.roles-checkboxes .checkbox.selected:after {
  border-left-color: #5bc0de;
}

.roles-checkboxes .checkbox.selectedActive label {
  color: #FFFFFF;
}

.roles-checkboxes .checkbox:first-child {
  padding: 5px 10px 5px 20px;
}

.roles-checkboxes .checkbox:last-child {
  padding: 5px 20px 5px 50px;
  -webkit-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-border-top-right-radius: 20px;
  border-top-right-radius: 20px;
  background-clip: padding-box;
}

.roles-checkboxes .checkbox:last-child:after {
  display: none;
}

.roles-checkboxes .checkbox label {
  padding: 0;
  margin: 0;
}

.roles-checkboxes .checkbox input {
  display: none;
}

.roles-checkboxes .checkbox:first-child {
  z-index: 19;
}

.roles-checkboxes .checkbox:nth-child(2) {
  z-index: 18;
}

.roles-checkboxes .checkbox:nth-child(3) {
  z-index: 17;
}

.roles-checkboxes .checkbox:nth-child(4) {
  z-index: 16;
}

.roles-checkboxes .checkbox:nth-child(5) {
  z-index: 15;
}

.roles-checkboxes .checkbox:nth-child(6) {
  z-index: 14;
}

.roles-checkboxes .checkbox:nth-child(7) {
  z-index: 13;
}

#form_edit_email .form-group,
#form_edit_transfers .form-group {
  display: block;
}

/* Small devices (tablets) */

@media (max-width: 767px) {
  .menu-bar #navbar ul.dropdown-menu a,
  .menu-bar .navbar ul.dropdown-menu a,
  .menu-bar li.open ul.dropdown-menu a,
  .menu-bar #navbar-xs ul.navbar-right a,
  .menu-bar-xs .navbar ul.navbar-right a,
  .menu-bar li.open ul.navbar-right a,
  .menu-bar #navbar-profile-xs ul.navbar-right a,
  .menu-bar #navbar-calendar-xs ul.navbar-right a {
    color: #FAFAFA;
  }

  .row-search {
    display: block;
  }

  .row-search .col {
    display: block;
    width: auto;
    padding: 3px 0 0 0;
  }

  .row-search .col:last-child {
    width: auto;
    text-align: left;
  }
}

/* Medium devices */

/* Large devices (large desktops) */

/* ELEMENTS */

/* PAS TOUCHE !!! */

/* STRUCTURE */

body {
  background-color: #FAFAFA;
}

body h1 {
  color: #666;
}

body h2 {
  color: #666;
}

body h3 {
  color: #666;
}

body p,
body label,
body th {
  color: #666;
}

body .container {
  background-color: transparent;
}

header {
  background-color: #fff;
}

div.layoutPublic .container-fluid.login {
  border-bottom-color: #fe9c05;
  background-color: #fff;
}

div.layoutPublic .container-fluid.login .container {
  background-color: transparent;
}

div.layoutPublic .container-fluid.login .login-link > a {
  color: #fe9c05;
}

div.layoutPublic .container-fluid.login .login-link > .fa {
  color: #4885b8;
}

.logoPublic {
  background-color: transparent;
}

.logoPublic section.logo {
  background-color: transparent;
}

div.breadcrumb {
  padding: 0;
  margin-top: 34px;
}

div.breadcrumb-franceConnect {
  padding: 0;
  margin-top: 20px;
}

footer {
  background-color: #FAFAFA;
  min-height: 200px !important;
}

footer .container {
  background-color: #FAFAFA;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 1px 0 0 0;
}

footer p {
  color: #666;
}

footer a {
  color: #fe9c05;
}

footer a:hover {
  color: #6a4000;
}

/* MENU */

.menu-bar {
  background-color: #4885b8;
}

.menu-bar .navbar,
.menu-bar .container {
  background-color: #4885b8;
}

.menu-bar #navbar a,
.menu-bar #navbar .navbar-brand,
.menu-bar #navbar .icon-bar,
.menu-bar li.open a,
.menu-bar li.open .navbar-brand,
.menu-bar li.open .icon-bar,
.menu-bar .navbar a,
.menu-bar .navbar .navbar-brand,
.menu-bar .navbar .icon-bar {
  color: #FAFAFA;
}

.menu-bar #navbar a:hover,
.menu-bar #navbar a:focus,
.menu-bar #navbar a:active,
.menu-bar #navbar .navbar-brand:hover,
.menu-bar #navbar .navbar-brand:focus,
.menu-bar #navbar .navbar-brand:active,
.menu-bar #navbar .icon-bar:hover,
.menu-bar #navbar .icon-bar:focus,
.menu-bar #navbar .icon-bar:active,
.menu-bar li.open a:hover,
.menu-bar li.open a:focus,
.menu-bar li.open a:active,
.menu-bar li.open .navbar-brand:hover,
.menu-bar li.open .navbar-brand:focus,
.menu-bar li.open .navbar-brand:active,
.menu-bar li.open .icon-bar:hover,
.menu-bar li.open .icon-bar:focus,
.menu-bar li.open .icon-bar:active,
.menu-bar .navbar a:hover,
.menu-bar .navbar a:focus,
.menu-bar .navbar a:active,
.menu-bar .navbar .navbar-brand:hover,
.menu-bar .navbar .navbar-brand:focus,
.menu-bar .navbar .navbar-brand:active,
.menu-bar .navbar .icon-bar:hover,
.menu-bar .navbar .icon-bar:focus,
.menu-bar .navbar .icon-bar:active {
  color: #e1e1e1;
}

.menu-bar #navbar ul.dropdown-menu a,
.menu-bar li.open ul.dropdown-menu a,
.menu-bar .navbar ul.dropdown-menu a {
  color: #666;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #FAFAFA;
}

.navbar-default .navbar-toggle {
  border-color: #FAFAFA;
}

#navbar > ul.nav.navbar-nav.navbar-right > li.warning {
  background-color: #c10909;
}

#menu-navbar-xs {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .dropdown-header {
    color: #fe9c05;
  }

  .menu-bar #navbar-profile-xs ul.navbar-right a.divider,
  .menu-bar #navbar-profile-xs ul.navbar-right li.divider,
  .menu-bar #navbar-xs ul.navbar-right a.divider,
  .menu-bar #navbar-xs ul.navbar-right li.divider {
    border-bottom: 1px solid #e7e7e7;
  }

  .menu-navbar-xs-button .navbar-toggle {
    margin: 8px 0;
  }

  #navbar-xs > ul.nav.navbar-nav.navbar-right > li.warning {
    background-color: #c10909;
  }

  .menu-navbar-xs-button {
    font-size: 12px;
  }

  .menu-navbar-xs-button .icon {
    height: 10px;
    display: inline-block;
  }

  .menu-navbar-xs-button .link {
    font-size: 12px;
    display: table;
    padding-top: 5px;
  }

  .menu-navbar-xs-button svg {
    height: 15px;
    width: 15px !important;
  }

  #button-navbar-home-xs,
  #button-navbar-search-xs,
  #button-navbar-calendar-xs,
  #button-navbar-profile-xs,
  #button-navbar-add-xs,
  #button-navbar-xs {
    border: none;
    color: #fff;
    line-height: 19px;
    height: 50px;
  }

  #button-navbar-home-xs:hover,
  #button-navbar-home-xs:focus,
  #button-navbar-search-xs:hover,
  #button-navbar-search-xs:focus,
  #button-navbar-calendar-xs:hover,
  #button-navbar-calendar-xs:focus,
  #button-navbar-add-xs:hover,
  #button-navbar-add-xs:focus,
  #button-navbar-profile-xs:hover,
  #button-navbar-profile-xs:focus,
  #button-navbar-xs:hover,
  #button-navbar-xs:focus {
    color: #000;
  }

  #button-navbar-xs:hover .icon-bar,
  #button-navbar-xs:focus .icon-bar {
    background-color: #000;
  }

  .footer_info {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#navbar > ul.navbar-nav > li.open > a {
  background-color: #6d9dc6;
}

@media only screen and (min-width: 480px) {
  .dropdown-header {
    color: #fe9c05;
  }
}

/* LIENS & BOUTONS */

.btn-primary {
  background-color: #fe9c05;
  border-color: white;
}

.btn-primary.btn-primary:hover {
  background-color: #e98d01;
  border-color: #feba51;
}

a,
.btn-link {
  color: #fe9c05;
}

a.calendar {
  background-color: #6095C1;
  border-color: #fff;
  color: #fff;
  margin-top: 20px;
}

a.calendar:hover {
  color: #FAFAFA;
}

a:hover,
a:focus,
a:active {
  color: #9c5f01;
}

.valign {
  vertical-align: middle !important;
}

div.layoutPublic .panel-register,
div.layoutPublic .panel-direct {
  border-color: #4885b8;
}

div.layoutPublic .panel-register a,
div.layoutPublic .panel-direct a {
  text-decoration: underline;
}

div.layoutPublic .panel-register a:hover,
div.layoutPublic .panel-direct a:hover {
  text-decoration: none;
}

.btn-mt-1 {
  margin-top: 15px;
}

/* HOMEPAGE */

.panel-login ul li:before {
  color: #fe9c05;
}

/*Register page */

.register .panel-body {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}

/* LIST OF PATIENT RECORD */

.btn-manage {
  background-color: #4885b8;
  color: #fff;
}

.btn-manage:hover,
.btn-manage:focus,
.btn-manage.focus {
  color: #e1e1e1;
}

.reports-list-right a {
  display: block;
  background-color: #4885b8;
  border-radius: 0 5px 5px 0;
  color: #ffffff;
}

.reports-list-right a:hover,
.reports-list-right a:focus {
  background-color: #396b94;
  color: #ffffff;
  text-decoration: none;
}

.reports-list-right a .btn-primary {
  background-color: #4885b8;
}

/* CODE PERSONNALISE CI-DESSOUS... */

.btn-primary {
  border-color: #e98d01;
}

.btn-disabled {
  opacity: 0.5;
  background: #CCCCCC;
}

.shared-persons-form-ins,
.share-activate-form-code,
.transfer-form-ins,
.transfer-form-code,
.form-login {
  margin: 0 0 20px;
}

.shared-persons-form-ins .form-group,
.share-activate-form-code .form-group,
.transfer-form-ins .form-group,
.transfer-form-code .form-group,
.form-login .form-group {
  display: block;
}

.badge-notifications {
  position: absolute;
  top: -15px;
  right: -10px;
  padding: 5px 10px;
  background: #e98d01;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
}

.badge-notifications:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(233, 141, 1, 0);
  border-top-color: #e98d01;
  border-width: 6px;
  margin-left: -6px;
}

.badge-notifications + .tooltip > .tooltip-inner {
  max-width: 100%;
  width: 200px;
  text-align: left;
  padding: 5px;
}

.crendu {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.5s ease;
}

.crendu:after {
  content: '';
  display: block;
  clear: both;
}

.crendu .crendu-elements-left {
  padding: 8px 15px;
  float: left;
}

.crendu .crendu-element {
  margin: 0 0 5px 0;
  display: block;
}

.crendu .crendu-left {
  padding: 10px 15px;
  width: 75%;
  float: left;
}

.crendu .crendu-right {
  padding: 10px 15px;
  text-align: right;
  width: 25%;
  float: right;
}

.crendu .crendu-details-nodemx {
  margin: -5px 0 5px;
  color: rgba(0, 0, 0, 0.7);
}

.crendu .crendu-normal-buttons {
  display: inline;
}

.crendu .crendu-normal-buttons > span,
.crendu .crendu-normal-buttons a {
  background-color: #6095C1 !important;
  border: 0 !important;
}

.crendu .crendu-normal-buttons > span:hover,
.crendu .crendu-normal-buttons a:hover {
  background: #396b94 !important;
}

.crendu .crendu-normal-buttons > span.btn-document,
.crendu .crendu-normal-buttons a.btn-document {
  background-color: #416793 !important;
  color: #FFFFFF !important;
}

.crendu .crendu-normal-buttons > span.btn-document:hover,
.crendu .crendu-normal-buttons a.btn-document:hover {
  background-color: #2e4a69 !important;
}

.crendu .payment-alert {
  text-align: center;
  color: #666666;
  padding: 10px;
}

.crendu .payment-alert i,
.crendu .payment-alert svg {
  display: block;
  font-size: 40px;
  width: 40px;
  height: 40px;
  margin: auto;
  padding: 0 0 10px;
}

.crendu .crendu-big-buttons br {
  display: block !important;
}

.crendu .crendu-big-buttons > span,
.crendu .crendu-big-buttons a {
  display: block;
  background-color: #fe9c05 !important;
  border: 0 !important;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  transition: 0.5s ease;
  width: 100%;
}

.crendu .crendu-big-buttons > span:hover,
.crendu .crendu-big-buttons a:hover {
  background: #e98d01 !important;
}

.crendu .crendu-big-buttons > span i,
.crendu .crendu-big-buttons > span svg,
.crendu .crendu-big-buttons a i,
.crendu .crendu-big-buttons a svg {
  font-size: 1em;
  line-height: 1;
  margin: auto;
  width: auto;
}

.crendu .crendu-big-buttons > span > span,
.crendu .crendu-big-buttons a > span {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.crendu .crendu-big-buttons > span.btn-document,
.crendu .crendu-big-buttons a.btn-document {
  background-color: #416793 !important;
}

.crendu .crendu-big-buttons > span.btn-document:hover,
.crendu .crendu-big-buttons a.btn-document:hover {
  background-color: #2e4a69 !important;
}

.crendu .crendu-big-buttons > span.btn-danger {
  background-color: #d9534f;
  cursor: default;
}

.crendu .crendu-big-buttons > span.btn-danger:hover {
  background-color: #d9534f !important;
}

.crendu .crendu-big-buttons .btn-notpaid {
  background-color: #d9534f !important;
  cursor: default;
}

.crendu .crendu-big-buttons .btn-notpaid span {
  white-space: normal;
}

.crendu .crendu-big-buttons .btn-notpaid:hover {
  background-color: #d9534f !important;
}

.crendu .pull-right .fa-times {
  display: none;
}

.crendu .pull-right .fa-info-circle {
  display: block;
}

.crendu .crendu-results {
  display: none;
  padding: 15px 0 0 0;
}

.crendu .crendu-top {
  padding: 0 0 5px;
  display: block;
  color: #fe9c05;
}

.crendu .crendu-top:after {
  content: '';
  clear: both;
  display: block;
}

.crendu .crendu-details .row {
  padding: 0 0 5px;
}

.crendu .crendu-details .row:last-child {
  padding: 0;
}

.crendu .crendu-details:after {
  display: table;
  clear: both;
  content: '';
}

.crendu .crendu-details .crendu-details-left {
  width: calc(60% - 10px);
  float: left;
}

.crendu .crendu-details .crendu-details-right {
  width: 40%;
  float: right;
}

/* FIX POUR LES CARTOUCHES DES CRs*/

@media (max-width: 767px) {
  span.visible-xs {
    display: inline-block !important;
  }

  .contentReportPrimary .reports-list-left-high,
  .contentReportPrimary .reports-list-right-high > a {
    height: auto;
  }

  .crendu .crendu-left,
  .crendu .crendu-right {
    float: none;
    width: auto;
  }

  .crendu .crendu-right a {
    display: block;
    position: static;
    max-width: 100%;
  }

  .crendu .crendu-details {
    display: none;
  }

  .crendu .crendu-details .crendu-details-left,
  .crendu .crendu-details .crendu-details-right {
    width: auto;
    float: none;
  }

  .crendu .crendu-details .crendu-details-right {
    padding: 0 15px;
  }

  .crendu.crendu-extended {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }

  .crendu.crendu-extended .crendu-details {
    display: block;
  }

  .crendu .crendu-big-buttons > span,
  .crendu .crendu-big-buttons a {
    position: static;
    border-radius: 5px;
    border: 1px solid #4885b8;
  }

  .crendu .crendu-big-buttons > span i,
  .crendu .crendu-big-buttons > span svg,
  .crendu .crendu-big-buttons a i,
  .crendu .crendu-big-buttons a svg {
    font-size: 1em;
    display: inline-block;
    padding: 0;
  }

  .crendu .crendu-big-buttons > span span,
  .crendu .crendu-big-buttons a span {
    display: inline-block;
  }

  .crendu .crendu-big-buttons > span > span,
  .crendu .crendu-big-buttons a > span {
    position: static;
    top: auto;
    transform: none;
  }

  .crendu .crendu-big-buttons > span.btn-danger {
    border: 1px solid #d9534f;
  }
}

.hash-site {
  padding: 5px;
  font-size: 10px;
  color: #888;
  font-family: monospace;
  text-align: center;
  display: none;
}

.hash-site.hash-site-dev,
.hash-site.hash-site-admin,
.hash-site.hash-site-demo {
  display: block;
}

.share-persons-page .share-cancel {
  display: none;
}

.autocomplete-suggestions {
  font-size: 16px;
  border: 1px solid #999;
  background: #FFF;
  overflow: auto;
}

.autocomplete-suggestions strong {
  font-weight: normal;
}

.autocomplete-suggestion {
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background-color: #F0F0F0;
}

.autocomplete-group {
  padding: 10px 5px 2px 10px;
  font-weight: bold;
  border-bottom: 1px solid #999;
}

#searchRDVWrapper {
  min-height: calc(100vh - 460px);
}

#searchResults {
  display: none;
}

#appointment_request_lieuEstLabo .radio {
  margin: 0 20px 0 0;
}

.information_prelevements {
  margin: 10px 0 0 0;
}

.home_rdv .panel-info-wrapper .panel {
  margin: 0 0 10px;
}

.home_rdv .panel-info-wrapper .panel .panel-footer {
  padding: 0 15px 10px 15px;
}

.home_rdv .panel-info-wrapper .panel-info-block {
  margin: 0 0 10px;
}

.labo-result {
  background: #FFF;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

a.locale_language {
  background: #f2f2f2;
  color: grey;
}

a.locale_language.selected,
a.locale_language:hover {
  background: #185091;
  color: #fff;
}

.mandatory-fields {
  font-size: 0.9em;
  color: red;
}

.mandatory-fields span {
  font-weight: bold;
}

.password-message {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9em;
}

.password-message .password-icon {
  display: inline-block;
  width: 20px;
}

.password-message .fa-times {
  color: rgba(0, 0, 0, 0.4);
}

.password-message .fa-check {
  color: green;
  display: none;
}

.password-message.password-message-checked {
  color: green;
}

.password-message.password-message-checked .fa-times {
  display: none;
}

.password-message.password-message-checked .fa-check {
  display: inline-block;
}

.appointment-bold {
  font-weight: bold;
}

.appointment-search {
  margin-bottom: 20px;
}

.franceconnect {
  margin: 0 0 20px;
}

.franceconnect p {
  text-align: center;
}

.franceconnect .franceconnect_button a {
  display: block;
  background-image: url("/build/themes/franceconnect.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 230px;
  height: 60px;
  margin: 0  auto 5px auto;
  overflow: hidden;
  text-indent: -5000px;
}

.franceconnect .franceconnect_button a:hover {
  background-image: url("/build/themes/franceconnect-hover.png");
}

.franceconnect .franceconnect_link {
  display: block;
  text-align: center;
  margin: 0  auto 20px auto;
  color: #185091;
}

.franceconnect .franceconnect_or {
  display: block;
  text-align: center;
  width: 40px;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  margin: auto;
  position: relative;
  color: rgba(0, 0, 0, 0.7);
}

.franceconnect .franceconnect_or:before,
.franceconnect .franceconnect_or:after {
  width: 140px;
  height: 2px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 8px;
  left: -140px;
  content: '';
}

.franceconnect .franceconnect_or:after {
  left: auto;
  right: -140px;
}

#personnes_transfer_personnes {
  padding: 0 0 15px 20px;
}

.margin-down {
  margin: 0 0 10px;
}

.bg-light-yellow {
  background: rgba(255, 255, 0, 0.15);
}

.bg-light-green {
  background: #dff0d8;
}

.bg-light-grey {
  background: #f9f9f9;
}

.login-password {
  padding: 0;
}

#heading_cookies .rotate {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#heading_cookies .collapsed .rotate {
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pb-15 {
  padding-bottom: 15px;
}

.db {
  display: block;
}

.share_green {
  color: #1a743f;
}

.share_blue {
  color: #7e3794;
}

.share_all {
  border-radius: 4px;
  background-color: #fff;
}

.share_all:hover {
  background-color: #fff;
}

.share {
  margin: 5px auto;
  padding: 10px;
}

.share_carousel {
  height: 360px;
  margin: 5px auto;
  padding: 10px;
}

.share_carousel img {
  margin: 5px auto 20px auto;
}

.carousel-indicators li {
  border: 1px solid #555;
}

.carousel-indicators .active {
  background-color: #555;
}

.carousel-caption {
  color: #000;
  position: initial;
  padding-top: 0;
  padding-bottom: 0;
}

.carousel-caption p {
  margin: 20px 0 0 0;
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
}

#carousel-share,
#carousel-transfer {
  border: 1px solid #555;
  border-radius: 5px;
}

.carousel-control,
.carousel-control:hover,
.carousel-control:focus {
  color: #20458F;
}

.share img {
  width: 180px;
}

h3.personTitle {
  color: #fe9c05;
}

.clue,
.clue-xs {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #20458F;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
}

#select_admin_entite_juridique {
  max-width: 350px;
  margin: auto;
  display: block;
}

#select_admin_entite_juridique input[type=submit] {
  display: none;
}

.top-france-connect {
  text-align: right;
  padding: 10px 0 0 0;
}

.top-france-connect p {
  margin: 0;
}

.card-labo {
  min-height: 300px;
}

.button-sms {
  padding: 10px;
  margin: 0 0 10px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
}

.button-sms:last-child {
  margin: 0;
}

.button-sms:hover {
  background: rgba(0, 0, 0, 0.1);
}

.copytext {
  cursor: pointer;
}

.buttons-line {
  text-align: center;
}

.buttons-line .btn,
.buttons-line button {
  display: inline-block;
  margin: 0 5px 10px 5px;
  width: auto;
}

.telephone_wrapper .iti {
  display: block;
  position: relative;
  z-index: 9999;
}

.loading_payment {
  text-align: center;
  padding: 50px 10px;
  margin: 20px 0;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.loading_payment i,
.loading_payment svg {
  display: block;
  font-size: 30px;
  height: 30px;
  margin: 0 auto 40px auto;
}

.loading_payment span {
  display: block;
}

.link-checkbox {
  color: #666;
  font-weight: bold;
}

.link-checkbox:hover {
  text-decoration: none;
}

.link-checkbox:hover:hover {
  color: #666;
}

.link-checkbox:hover:hover span {
  color: #000;
}

